import { ErrorBoundary } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import { Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useCallback } from 'react'

import { configurations } from '../../segmentConfigurations'
import {
  DEFAULT_TEXT_STATE,
  SegmentState,
  useSegmentStatesContext,
} from '../../state/segment-state/useSegmentStates'
import {
  Channel,
  PhoneSegmentBuilderState,
  TextSegmentBuilderState,
} from '../../types'
import {
  MultiSegmentContainerProps,
  PhoneFilterWithIndex,
  TextFilterWithIndex,
} from './MultiSegmentContainer'
import SkeletonLoader from './SkeletonLoader'
import { FilterEvent, logEvent } from './events'

export type SegmentsSubFilterContainerProps = Pick<
  MultiSegmentContainerProps,
  | 'filterDefinitions'
  | 'onStateChange'
  | 'onSavedFilterChange'
  | 'segmentProfiles'
  | 'disabledChannels'
  | 'resetSavedFilterOnValuesChange'
>

const resetStateIfNeeded = (
  oldState: SegmentState,
  newState: PhoneSegmentBuilderState | TextSegmentBuilderState,
) => {
  let state = newState
  if (
    state.channel === 'phone' &&
    oldState.channel === 'phone' &&
    oldState.subFilterState &&
    oldState.subFilterState.savedFilter !== undefined &&
    state.savedFilter === oldState.subFilterState.savedFilter &&
    state.values !== oldState.subFilterState.values
  ) {
    state = { ...state, savedFilter: undefined }
  }

  if (
    state.channel === 'email' &&
    oldState.channel === 'email' &&
    oldState.subFilterState?.savedTextFilter !== undefined &&
    state.savedTextFilter === oldState.subFilterState.savedTextFilter &&
    state.values !== oldState.subFilterState.values
  ) {
    state = { ...state, savedTextFilter: undefined }
  }

  return state
}

export const SegmentsSubFilterContainer: React.FC<
  SegmentsSubFilterContainerProps
> = ({
  disabledChannels,
  filterDefinitions,
  onStateChange,
  onSavedFilterChange,
  resetSavedFilterOnValuesChange,
  segmentProfiles = configurations,
}) => {
  const maxSegments = segmentProfiles.length

  const { states, addState, removeState, hasValues, updateSubFilterState } =
    useSegmentStatesContext()

  const handleAddState = (): void => {
    const newIndex = states.length
    addState()
    logEvent(FilterEvent.AddSegment, {
      segmentLabel: segmentProfiles[newIndex].label,
    })
  }

  const handleRemoveState = (index: number): void => {
    removeState(index)
    logEvent(FilterEvent.RemoveSegment, {
      segmentLabel: segmentProfiles[index].label,
    })
  }

  const handleUpdateState = useCallback(
    ({
      state: stateProp,
      index,
    }: {
      state: PhoneSegmentBuilderState | TextSegmentBuilderState
      index: number
    }) => {
      const state = resetSavedFilterOnValuesChange
        ? resetStateIfNeeded(states[index], stateProp)
        : stateProp
      updateSubFilterState(state, index)
      onStateChange?.(state, index)
    },
    [
      onStateChange,
      updateSubFilterState,
      states,
      resetSavedFilterOnValuesChange,
    ],
  )

  const handleChannelChange = useCallback(
    (channel: Channel, index: number) => {
      if (channel === 'phone') {
        handleUpdateState({ state: { channel: channel, values: {} }, index })
      } else {
        handleUpdateState({
          state: { channel: channel, values: { ...DEFAULT_TEXT_STATE } },
          index,
        })
      }
    },
    [handleUpdateState],
  )

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<SkeletonLoader comparable />}>
        <Box>
          {states.map((s, i) =>
            s.channel === 'phone' ? (
              <PhoneFilterWithIndex
                index={i}
                key={s.id}
                label={segmentProfiles[i].label}
                color={segmentProfiles[i].color}
                disabledChannels={disabledChannels}
                filterDefinitions={filterDefinitions}
                onSavedFilterChange={onSavedFilterChange}
                onStateChange={handleUpdateState}
                onChannelChange={handleChannelChange}
                state={
                  s.subFilterState
                    ? {
                        channel: s.channel,
                        ...s.subFilterState,
                      }
                    : undefined
                }
                showResetButton={states.length === 1 && hasValues(i)}
              >
                {states.length > 1 && (
                  <Button
                    variant="ghost"
                    colorScheme="gray"
                    onClick={() => handleRemoveState(i)}
                    size="sm"
                    px={2}
                  >
                    <Trans>Remove</Trans>
                  </Button>
                )}
              </PhoneFilterWithIndex>
            ) : (
              <TextFilterWithIndex
                key={s.id}
                index={i}
                segmentState={
                  s.subFilterState
                    ? { channel: s.channel, ...s.subFilterState }
                    : undefined
                }
                initialValue={s.subFilterState?.values ?? DEFAULT_TEXT_STATE}
                configuration={segmentProfiles[i]}
                disabledChannels={disabledChannels}
                onSegmentStateChange={handleUpdateState}
                onChannelChange={handleChannelChange}
              >
                {states.length > 1 && (
                  <Button
                    variant="ghost"
                    colorScheme="gray"
                    onClick={() => handleRemoveState(i)}
                    size="sm"
                    px={2}
                  >
                    <Trans>Remove</Trans>
                  </Button>
                )}
              </TextFilterWithIndex>
            ),
          )}

          {states.length < maxSegments && (
            <Button
              variant="link"
              size="xs"
              colorScheme="primary"
              my={2}
              onClick={handleAddState}
              data-stonly="Add comparison"
            >
              <Trans>Add comparison</Trans>
            </Button>
          )}
        </Box>
      </React.Suspense>
    </ErrorBoundary>
  )
}
